import React from 'react';
const Landing = () => {
  return (
    <div className="welcome-container">
      <div className="welcome-message">Welcome to QPT Art Gallery!</div>
      <h2>Art Life, Live Art</h2>
      <img src="landing_pic.jpg"></img>
      <p>QPT Gallery represents brilliant artists worldwide, displaying their art in our breathtaking galleries in prime locations around the world.</p>
      <p>
        Explore our features and services. Feel free to contact us for more information.
      </p>
      <p>COMING SOON...</p>
    </div>
  )
}

export default Landing;