
const Header = () => {
  return (
    <>
      <div className="navbar">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
    </>
  )
}

export default Header;