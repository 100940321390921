import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Landing from './components/Landing';

function App() {
  return (
    <div className="App">
      <div>
        <Header />
        <Landing />
      </div>
    </div>

  );
}

export default App;
